<template>
    <v-container
        :key="componentKey"
        id="users"
        fluid
        class="fill-height flex-column flex-nowrap align-start"
    >
        <v-container fluid class="pt-5 px-5">
            <v-row justify="space-between">
                <v-col md="auto" cols="12" class="d-flex">
                    <v-toolbar-title class="headline">Profile</v-toolbar-title>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid>
            <v-row v-if="userData.loading" justify="center">
                <span>Loading User...</span>
            </v-row>
            <v-row class="px-8" v-else>
                <!-- FIRST COLUMN -->
                <v-col
                    md="4"
                    cols="12"
                    class="d-flex flex-column"
                    style="gap: 20px"
                >
                    <div>
                        <div class="d-flex align-center bg-red">
                            <h3
                                class="text-md font-weight-bold"
                                style="color: #389877"
                            >
                                Personal
                            </h3>
                            <h3
                                :class="
                                    !isEditingForm.personal
                                        ? 'ml-2'
                                        : 'ml-auto mr-14'
                                "
                            >
                                <v-hover v-slot="{ hover }">
                                    <v-btn
                                        block
                                        depressed
                                        dark
                                        :color="hover ? '#389877' : '#2c443c'"
                                        small
                                        rounded
                                        class="edit-btn"
                                        style="max-height: 22px"
                                        v-if="!isEditingForm.personal"
                                        @click="toggleEdit('personal')"
                                    >
                                        <v-icon color="white" small dense
                                            >mdi-pencil-outline</v-icon
                                        >
                                    </v-btn>
                                    <v-btn
                                        block
                                        depressed
                                        dark
                                        :color="hover ? '#bd4343' : '#973636'"
                                        small
                                        rounded
                                        class="edit-btn"
                                        style="max-height: 22px"
                                        v-else
                                        @click="toggleEdit('personal')"
                                    >
                                        <v-icon color="white" small dense
                                            >mdi-close</v-icon
                                        >
                                    </v-btn>
                                </v-hover>
                            </h3>
                        </div>
                        <v-row class="px-2">
                            <v-col
                                md="12"
                                cols="12"
                                class="d-flex flex-column"
                                style="gap: 15px"
                            >
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold mt-4"
                                        >Prefix</label
                                    ><span
                                        style="width: 50%; font-size: 14px"
                                        class="mt-4"
                                        v-if="!isEditingForm.personal"
                                        >{{
                                            getFieldFormat(userData.data.prefix)
                                        }}</span
                                    >
                                    <v-text-field
                                        v-else
                                        v-model="userData.data.prefix"
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        class="mr-13 mt-4"
                                        dense
                                        clearable
                                        hide-details
                                    />
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >First Name</label
                                    ><span
                                        v-if="!isEditingForm.personal"
                                        style="width: 50%; font-size: 14px"
                                        >{{
                                            getFieldFormat(
                                                userData.data.first_name
                                            )
                                        }}</span
                                    >
                                    <v-text-field
                                        v-else
                                        v-model="userData.data.first_name"
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        class="mr-13"
                                        dense
                                        clearable
                                        hide-details
                                    />
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Middle Name</label
                                    ><span
                                        v-if="!isEditingForm.personal"
                                        style="width: 50%; font-size: 14px"
                                        >{{
                                            getFieldFormat(
                                                userData.data.middle_name
                                            )
                                        }}</span
                                    >
                                    <v-text-field
                                        v-else
                                        v-model="userData.data.middle_name"
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        class="mr-13"
                                        dense
                                        clearable
                                        hide-details
                                    />
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Last Name</label
                                    ><span
                                        v-if="!isEditingForm.personal"
                                        style="width: 50%; font-size: 14px"
                                        >{{
                                            getFieldFormat(
                                                userData.data.last_name
                                            )
                                        }}</span
                                    >
                                    <v-text-field
                                        v-else
                                        v-model="userData.data.last_name"
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        class="mr-13"
                                        dense
                                        clearable
                                        hide-details
                                    />
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Suffix</label
                                    ><span
                                        v-if="!isEditingForm.personal"
                                        style="width: 50%; font-size: 14px"
                                        >{{
                                            getFieldFormat(userData.data.suffix)
                                        }}</span
                                    >
                                    <v-text-field
                                        v-else
                                        v-model="userData.data.suffix"
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        class="mr-13"
                                        dense
                                        clearable
                                        hide-details
                                    />
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Nickname</label
                                    ><span
                                        v-if="!isEditingForm.personal"
                                        style="width: 50%; font-size: 14px"
                                        >{{
                                            getFieldFormat(
                                                userData.data.nickname
                                            )
                                        }}</span
                                    >
                                    <v-text-field
                                        v-else
                                        v-model="userData.data.nickname"
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        class="mr-13"
                                        dense
                                        clearable
                                        hide-details
                                    />
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Birthdate</label
                                    ><span
                                        v-if="!isEditingForm.personal"
                                        style="width: 50%; font-size: 14px"
                                        >{{
                                            getFieldFormat(
                                                userData.data.birthdate
                                            ).replace(/-/g, "/")
                                        }}</span
                                    >
                                    <base-date-picker
                                        v-else
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        customClass="mr-13 ml-4"
                                        dense
                                        clearable
                                        hide-details
                                        v-model="userData.data.birthdate"
                                    />
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Gender</label
                                    ><span
                                        v-if="!isEditingForm.personal"
                                        style="width: 50%; font-size: 14px"
                                        >{{
                                            getFieldFormat(userData.data.gender)
                                        }}</span
                                    >
                                    <v-text-field
                                        v-else
                                        v-model="userData.data.gender"
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        class="mr-13"
                                        dense
                                        clearable
                                        hide-details
                                    />
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Marital Status</label
                                    ><span
                                        v-if="!isEditingForm.personal"
                                        style="width: 50%; font-size: 14px"
                                        >{{
                                            getFieldFormat(
                                                userData.data.marital_status
                                            )
                                        }}</span
                                    >
                                    <v-text-field
                                        v-else
                                        v-model="userData.data.marital_status"
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        class="mr-13"
                                        dense
                                        clearable
                                        hide-details
                                    />
                                </div>
                                <v-btn
                                    class="form-save save-btn"
                                    depressed
                                    dark
                                    color="#2C423D"
                                    rounded
                                    v-if="isEditingForm.personal"
                                    @click="updateProfileData"
                                    >Save</v-btn
                                >
                            </v-col>
                        </v-row>
                    </div>
                </v-col>

                <!-- SECOND COLUMN -->
                <v-col
                    md="4"
                    cols="12"
                    class="d-flex flex-column"
                    style="gap: 40px"
                >
                    <div>
                        <div class="d-flex align-center">
                            <h3
                                class="text-md font-weight-bold"
                                style="color: #389877"
                            >
                                Contact
                            </h3>
                            <h3
                                :class="
                                    !isEditingForm.contact
                                        ? 'ml-2'
                                        : 'ml-auto mr-14'
                                "
                            >
                                <v-hover v-slot="{ hover }">
                                    <v-btn
                                        block
                                        depressed
                                        dark
                                        :color="hover ? '#389877' : '#2c443c'"
                                        small
                                        rounded
                                        class="edit-btn"
                                        style="max-height: 22px"
                                        v-if="!isEditingForm.contact"
                                        @click="toggleEdit('contact')"
                                    >
                                        <v-icon color="white" small dense
                                            >mdi-pencil-outline</v-icon
                                        >
                                    </v-btn>
                                    <v-btn
                                        block
                                        depressed
                                        dark
                                        :color="hover ? '#bd4343' : '#973636'"
                                        small
                                        rounded
                                        class="edit-btn"
                                        style="max-height: 22px"
                                        v-else
                                        @click="toggleEdit('contact')"
                                    >
                                        <v-icon color="white" small dense
                                            >mdi-close</v-icon
                                        >
                                    </v-btn>
                                </v-hover>
                            </h3>
                        </div>
                        <v-row class="px-2 mt-1">
                            <v-col
                                md="12"
                                cols="12"
                                class="d-flex flex-column"
                                style="gap: 15px"
                            >
                                <div class="d-flex">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Address</label
                                    ><span
                                        style="width: 50%; font-size: 14px"
                                        v-if="!isEditingForm.contact"
                                        >{{
                                            getFieldFormat(
                                                userData.data.address
                                            )
                                        }}</span
                                    >
                                    <v-textarea
                                        v-else
                                        no-resize
                                        v-model="userData.data.address"
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        class="mr-13"
                                        dense
                                        hide-details
                                    />
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Contact Number</label
                                    ><span
                                        v-if="!isEditingForm.contact"
                                        style="width: 50%; font-size: 14px"
                                        >{{
                                            getFieldFormat(
                                                userData.data.contact_number
                                            )
                                        }}</span
                                    >
                                    <v-text-field
                                        v-else
                                        v-model="userData.data.contact_number"
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        class="mr-13"
                                        dense
                                        clearable
                                        hide-details
                                    />
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Email Address</label
                                    ><span
                                        v-if="!isEditingForm.contact"
                                        style="width: 50%; font-size: 14px"
                                        >{{
                                            getFieldFormat(userData.data.email)
                                        }}</span
                                    >
                                    <v-text-field
                                        v-else
                                        v-model="userData.data.email"
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        class="mr-13"
                                        dense
                                        clearable
                                        hide-details
                                    />
                                </div>
                                <v-btn
                                    class="form-save save-btn"
                                    depressed
                                    dark
                                    color="#2C423D"
                                    rounded
                                    v-if="isEditingForm.contact"
                                    @click="updateProfileData"
                                    >Save</v-btn
                                >
                            </v-col>
                        </v-row>
                    </div>
                    <div>
                        <div class="d-flex align-center">
                            <h3
                                class="text-md font-weight-bold"
                                style="color: #389877"
                            >
                                Work
                            </h3>
                            <h3
                                :class="
                                    !isEditingForm.work
                                        ? 'ml-2'
                                        : 'ml-auto mr-14'
                                "
                            >
                                <v-hover v-slot="{ hover }">
                                    <v-btn
                                        block
                                        depressed
                                        dark
                                        :color="hover ? '#389877' : '#2c443c'"
                                        small
                                        rounded
                                        class="edit-btn"
                                        style="max-height: 22px"
                                        v-if="!isEditingForm.work"
                                        @click="toggleEdit('work')"
                                    >
                                        <v-icon color="white" small dense
                                            >mdi-pencil-outline</v-icon
                                        >
                                    </v-btn>
                                    <v-btn
                                        block
                                        depressed
                                        dark
                                        :color="hover ? '#bd4343' : '#973636'"
                                        small
                                        rounded
                                        class="edit-btn"
                                        style="max-height: 22px"
                                        v-else
                                        @click="toggleEdit('work')"
                                    >
                                        <v-icon color="white" small dense
                                            >mdi-close</v-icon
                                        >
                                    </v-btn>
                                </v-hover>
                            </h3>
                        </div>
                        <v-row class="px-2 mt-1">
                            <v-col
                                md="12"
                                cols="12"
                                class="d-flex flex-column"
                                style="gap: 15px"
                            >
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Occupation</label
                                    ><span
                                        v-if="!isEditingForm.work"
                                        style="width: 50%; font-size: 14px"
                                        >{{
                                            getFieldFormat(
                                                userData.data?.user_information
                                                    ?.occupation
                                            )
                                        }}</span
                                    >
                                    <v-text-field
                                        v-else
                                        v-model="
                                            userData.data.user_information
                                                .occupation
                                        "
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        class="mr-13"
                                        dense
                                        clearable
                                        hide-details
                                    />
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Company</label
                                    ><span
                                        v-if="!isEditingForm.work"
                                        style="width: 50%; font-size: 14px"
                                        >{{
                                            getFieldFormat(
                                                userData.data?.user_information
                                                    ?.company
                                            )
                                        }}</span
                                    >
                                    <v-text-field
                                        v-else
                                        v-model="
                                            userData.data.user_information
                                                .company
                                        "
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        class="mr-13"
                                        dense
                                        clearable
                                        hide-details
                                    />
                                </div>
                                <v-btn
                                    class="form-save save-btn"
                                    depressed
                                    dark
                                    color="#2C423D"
                                    @click="updateProfileData"
                                    rounded
                                    v-if="isEditingForm.work"
                                    >Save</v-btn
                                >
                            </v-col>
                        </v-row>
                    </div>

                    <div>
                        <h3
                            class="text-md font-weight-bold"
                            style="color: #389877"
                        >
                            Relationships
                        </h3>
                        <v-row class="px-2 mt-1">
                            <v-col
                                md="12"
                                cols="12"
                                class="d-flex flex-column"
                                style="gap: 15px"
                            >
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Father</label
                                    ><span style="width: 50%; font-size: 14px"
                                        >Father</span
                                    >
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Mother</label
                                    ><span style="width: 50%; font-size: 14px"
                                        >Mother</span
                                    >
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Siblings</label
                                    ><span style="width: 50%; font-size: 14px"
                                        >Siblings</span
                                    >
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Spouse</label
                                    ><span style="width: 50%; font-size: 14px"
                                        >Spouse</span
                                    >
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Children</label
                                    ><span style="width: 50%; font-size: 14px"
                                        >Children</span
                                    >
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>

                <!-- THIRD COLUMN -->
                <v-col
                    md="4"
                    cols="12"
                    class="d-flex flex-column"
                    style="gap: 20px"
                >
                    <!-- <div>
                        <div class="d-flex align-center">
                            <h3
                                class="text-md font-weight-bold"
                                style="color: #389877"
                            >
                                Preferences
                            </h3>
                            <h3
                                :class="
                                    !isEditingForm.preferences
                                        ? 'ml-2'
                                        : 'ml-auto mr-14'
                                "
                            >
                                <v-hover v-slot="{ hover }">
                                    <v-btn
                                        block
                                        depressed
                                        dark
                                        :color="hover ? '#389877' : '#2c443c'"
                                        small
                                        rounded
                                        class="edit-btn"
                                        style="max-height: 22px"
                                        v-if="!isEditingForm.preferences"
                                        @click="toggleEdit('preferences')"
                                    >
                                        <v-icon color="white" small dense
                                            >mdi-pencil-outline</v-icon
                                        >
                                    </v-btn>
                                    <v-btn
                                        block
                                        depressed
                                        dark
                                        :color="hover ? '#bd4343' : '#973636'"
                                        small
                                        rounded
                                        class="edit-btn"
                                        style="max-height: 22px"
                                        v-else
                                        @click="toggleEdit('preferences')"
                                    >
                                        <v-icon color="white" small dense
                                            >mdi-close</v-icon
                                        >
                                    </v-btn>
                                </v-hover>
                            </h3>
                        </div>
                        <v-row class="px-2 mt-1">
                            <v-col
                                md="12"
                                cols="12"
                                class="d-flex flex-column"
                                style="gap: 15px"
                            >
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >Bulk Email</label
                                    ><span style="width: 50%; font-size: 14px"
                                        >Bulk Email</span
                                    >
                                </div>
                                <div class="d-flex align-center">
                                    <label
                                        style="
                                            width: 50%;
                                            color: #78756a;
                                            font-size: 14px;
                                        "
                                        class="text-uppercase font-weight-bold"
                                        >SMS Messages</label
                                    ><span style="width: 50%; font-size: 14px"
                                        >SMS Messages</span
                                    >
                                </div>
                                <v-btn
                                    class="form-save save-btn"
                                    depressed
                                    dark
                                    color="#2C423D"
                                    rounded
                                    v-if="isEditingForm.preferences"
                                    >Save</v-btn
                                >
                            </v-col>
                        </v-row>
                    </div> -->
                    <div>
                        <h3
                            class="text-md font-weight-bold"
                            style="color: #389877"
                        >
                            Update Password
                        </h3>
                        <v-row class="px-2 mt-1">
                            <v-col md="9" cols="12" class="d-flex flex-column">
                                <v-form
                                    @submit.prevent="handleSubmitChangePassword"
                                    ref="passwordForm"
                                >
                                    <v-text-field
                                        label="Current Password"
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        dense
                                        clearable
                                        v-model="passwordForm.old_password"
                                        type="password"
                                        @keyup.enter="
                                            handleSubmitChangePassword
                                        "
                                        :rules="[$validate.rules.required]"
                                        required
                                    ></v-text-field>
                                    <v-text-field
                                        label="New Password"
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        type="password"
                                        dense
                                        clearable
                                        v-model="passwordForm.password"
                                        :rules="[$validate.rules.required]"
                                        @keyup.enter="
                                            handleSubmitChangePassword
                                        "
                                        required
                                    ></v-text-field>
                                    <v-text-field
                                        label="Confirm Password"
                                        autocomplete="off"
                                        color="primaryTextColor"
                                        flat
                                        outlined
                                        type="password"
                                        dense
                                        clearable
                                        v-model="
                                            passwordForm.password_confirmation
                                        "
                                        :rules="[$validate.rules.required]"
                                        required
                                        @keyup.enter="
                                            handleSubmitChangePassword
                                        "
                                    ></v-text-field>
                                    <v-btn
                                        class="save-btn"
                                        depressed
                                        dark
                                        color="#2C423D"
                                        :loading="passwordForm.loading"
                                        rounded
                                        @click.prevent="
                                            handleSubmitChangePassword
                                        "
                                        >Save</v-btn
                                    >
                                </v-form>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import { GET_PROFILE } from "@/store/modules/profile";
import { UPDATE_USER_PASSWORD, UPDATE_USER } from "@/store/modules/user";
import BaseDatePicker from "../../Base/BaseDatePicker";
import { cloneDeep } from "lodash";

export default {
    components: {
        BaseDatePicker,
    },
    data() {
        return {
            componentKey: 0,
            userData: {
                loading: false,
                data: {},
            },
            initialData: {},
            passwordForm: {
                loading: false,
                old_password: "",
                password: "",
                password_confirmation: "",
            },
            isEditingForm: {
                personal: false,
                contact: false,
                work: false,
                preferences: false,
            },
        };
    },
    computed: {
        currentUserID() {
            return this.$cookies.getValue("user").id;
        },
    },
    methods: {
        updateProfileData() {
            this.userData.loading = true;
            const variables = {
                ...this.initialData.data,
                ...this.userData.data,
                userID: this.initialData.data.id,
                membership_id:
                    this.initialData.data.user_information.membership_id,
                boe_resolution:
                    this.initialData.data.user_information.boe_resolution,
                company: this.userData.data.user_information.company,
                occupation: this.userData.data.user_information.occupation,
                designations: this.initialData.data.user_designations,
                roles: this.initialData.data.user_roles,
            };
            delete variables.access_controls;
            delete variables.user_information;
            delete variables.user_designations;
            delete variables.user_groups;
            delete variables.user_milestones;
            delete variables.user_permissions;
            delete variables.user_relationships;
            delete variables.user_roles;
            delete variables.user_settings;

            this.$store
                .dispatch(UPDATE_USER, variables)
                .then(() => {
                    this.isEditingForm.personal = false;
                    this.isEditingForm.contact = false;
                    this.isEditingForm.work = false;
                    this.isEditingForm.preferences = false;
                    this.getCurrentUser(this.currentUserID);
                    this.$notify({
                        group: "main-notifications",
                        type: "success",
                        title: "Update User",
                        text: "Successfully updated user data",
                    });
                })
                .catch((error) => {
                    this.userData.loading = false;
                    let text = [];
                    if (error.response.data.errors) {
                        error.response.data.errors.forEach((item) => {
                            for (let value in item) {
                                text = [...text, `${item[value][0]} <br/>`];
                            }
                        });
                    }
                });
        },
        getFieldFormat(value) {
            if (value === undefined || value === null || value === "")
                return "-";
            return value;
        },
        getCurrentUser(id) {
            this.userData.loading = true;
            this.userData.data = {};
            this.$store
                .dispatch(GET_PROFILE, id)
                .then((response) => {
                    this.userData.data = response.data.data;
                    this.userData.loading = false;
                    this.initialData = cloneDeep(this.userData);
                })
                .catch((error) => {
                    this.userData.loading = false;
                    let text = [];
                    if (error.response.data.errors) {
                        error.response.data.errors.forEach((item) => {
                            for (let value in item) {
                                text = [...text, `${item[value][0]} <br/>`];
                            }
                        });
                    }
                });
        },
        handleSubmitChangePassword() {
            let formStatus = this.$refs.passwordForm.validate();
            if (formStatus) {
                this.passwordForm.userID = this.currentUserID;
                this.$store
                    .dispatch(UPDATE_USER_PASSWORD, this.passwordForm)
                    .then(() => {
                        this.$notify({
                            group: "main-notifications",
                            type: "error",
                            title: "Change Password",
                            text: "Successfully changed",
                        });
                    })
                    .catch((error) => {
                        let text = "";
                        this.userData.loading = false;
                        if (error.response.data.errors) {
                            if (error.response.data.errors.old_password) {
                                text =
                                    error.response.data.errors.old_password[0];
                            } else {
                                text = error.response.data.errors.password[0];
                            }
                        }
                        this.$notify({
                            group: "main-notifications",
                            type: "error",
                            title: "Error updating password",
                            text: text,
                        });
                    });
            }
        },
        toggleEdit(form) {
            this.isEditingForm[form] = !this.isEditingForm[form];
            this.userData = cloneDeep(this.initialData);
        },
    },
    mounted() {
        this.getCurrentUser(this.currentUserID);
    },
};
</script>

<style lang="scss" scoped>
.close-btn {
    margin-left: 100%;
}
.edit-btn {
    padding: 0 8px 0 8px !important;
}

.save-btn {
    margin-left: -10px;
}

.mr-13 {
    min-width: 48%;
}

.form-save {
    max-width: fit-content;
    margin: 10px 10% 0 0;
}

.v-text-field .v-input__control .v-input__slot {
    min-height: 41px !important;
    display: flex !important;
}
</style>
